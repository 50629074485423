<template>
  <div class='withdrawal'>
    <layout-header />
    <main class='withdrawal__main'>
      <section class='withdrawal__main__modal'>
        <h3>Donblerを退会する</h3>
        <p>アカウントを削除すると復活できませんがよろしいですか？</p>
        <div class='withdrawal__main__modal__button'>
          <button-normal
            @emitClick='execDeleteUser()'
            :loading='isLoading.deleteUser'
          >
            <p>
              退会する
            </p>
          </button-normal>
        </div>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
  },
  data() {
    return {
      isLoading: {
        deleteUser: false,
      },
    };
  },
  methods: {
    async execDeleteUser() {
      this.isLoading.deleteUser = true;

      const result = await this.$store.dispatch('deleteUser');
      if (await result === 401 || await result === true) {
        this.$router.push({ name: 'Top' });
      } else {
        this.isLoading.deleteUser = false;
        this.$notify({
          title: 'アカウントの削除に失敗しました',
          text: 'しばらく時間を空けてから再度お試しください',
          speed: 400,
          type: 'error',
          group: 'top-right',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.withdrawal {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.withdrawal__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.withdrawal__main__modal {
  background-color: $color-white;
  width: 500px;
  text-align: center;
  padding: 30px 30px 35px;
  h3 {
    font-size: 24px;
  }
  >p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  a:visited {
    color: $color-black;
  }
}

.withdrawal__main__modal__button {
  display: flex;
  justify-content: center;
}
</style>
